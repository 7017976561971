import React, { useState } from 'react'
import { Layout } from '../components/layout'
import { StyledAbout } from '../components/about/styles'
import { Seo } from '../components/common'
import { Skeleton } from '@mui/material'

export default function AboutPage() {
  const [item, setItem] = useState(false)

  return (
    <Layout>
      <div className="mt-3 w-full relative" style={{ height: '385px', width: '1024px' }}>
        <Skeleton
          variant="rect"
          className="rounded-md shadow-lg absolute top-0 left-0 w-full h-full"
          style={{ opacity: item ? 0 : 1 }}
        />
        <img
          className="rounded-md shadow-lg w-full h-full"
          src={'https://picsum.photos/800/300'}
          alt="My Image"
          onLoad={() => setItem(true)}
          style={{ opacity: item ? 1 : 0, transition: 'opacity 1s ease-in' }}
        />
      </div>
      <p className="text-center mt-3 text-xl text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-blue-500">
        "Act as if what you do makes a difference. It does." - William James
      </p>
      <StyledAbout style={{ marginTop: '50px' }}>
        {/* <h1>About</h1> */}
        <div>
          <div className="section starter">
            <h2>Nice to meet you ! </h2>
            <p>
              Hello, my name is Khylon Wong and it's my pleasure to welcome you to my personal webpage. I'm currently
              working towards my Ph.D. degree at{' '}
              <a href="https://ilearn.qd.sdu.edu.cn/" target="_blank" rel="noopener noreferrer">
                Intelligent Media Research Center (iLearn)
              </a>{' '}
              ,{' '}
              <a href="https://www.sdu.edu.cn/" target="_blank" rel="noopener noreferrer">
                Shandong University(SDU)
              </a>{' '}
              . Prior to pursuing my doctoral studies, I completed my B.S. degrees in Computer Science and Technology
              from the same university in 2022.
            </p>
            <p>
              I find great joy in taking on creative challenges and enjoy cultivating meaningful relationships as I work
              towards my goals. If you're interested in learning more, please feel free to peruse my history below and
              don't hesitate to contact me directly. <span>{'😉'}</span>
            </p>
          </div>
          <div className="section tech">
            <h2>Research Interests</h2>
            <p>
              Inspired by my mentor{' '}
              <a className="gatsby" href="https://liqiangnie.github.io/" target="_blank" rel="noopener noreferrer">
                Prof. Liqiang Nie
              </a>{' '}
              and co-mentor {' '}
              <a className="gatsby" href="https://faculty.sdu.edu.cn/huyupeng1/zh_CN/index.htm" target="_blank" rel="noopener noreferrer">
                Prof. Yupeng Hu
              </a>, I have been striving to pursue research that is more closely related to the industry. Therefore, I have
              focused on designing structured and lightweight models that can achieve better semantic understanding in
              complex environments, including but not limited to text, images, and videos.{' '}
            </p>
            <p>
              I believe that there is a bridge between machines and humans, and by abstracting perceived scene elements
              as "higher-level" symbols, we can enjoy the prior knowledge and leading to unprecedented advances in
              artificial intelligence. 
              {/* My work explores the following areas:
              <ul>
                <li>Relational Reasoning : modal semantic consistency, and complementarity capture.</li>
                <li>Cross-Modal Approximate Nearest-Neighbor Search : Hashing, Quantization, Graph Indexing, etc.</li>
                <li>
                  Some lightweight applications include (but are not limited to) Model Distillation, Model Compression.{' '}
                  <del>And even SIMD acceleration on CPU 🤣.</del>
                </li>
              </ul>
              Ultimately, the goal of all these research directions is to fully embrace large models. */}
            </p>
            {/* <ul>
              <li>
                <a className="gatsby" href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">
                  Gatsby:
                </a>{' '}
                A blazing fast React framework for building websites & apps.
              </li>
              <li>
                <a
                  className="emotion"
                  href="https://emotion.sh/docs/introduction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Emotion:
                </a>{' '}
                Library designed for writing css styles with JavaScript.
              </li>
              <li>
                <a className="tailwind" href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer">
                  Tailwind CSS:
                </a>{' '}
                CSS framework that gives you all of the building blocks you need to build bespoke designs with low-level
                utility classes.
              </li>
            </ul> */}
          </div>
          <div className="section features">
            <h2>I Love SMILE ! </h2>
            <ul>
              <li>
                <b>S</b> stands for Strength 💪
              </li>
              <li>
                <b>M</b> stands for Motivation 🔥
              </li>
              <li>
                <b>I</b> stands for Innovation 💡
              </li>
              <li>
                <b>L</b> stands for Leadership 👑
              </li>
              <li>
                <b>E</b> stands for Empathy ❤️
              </li>
              {/* <li>
                Host your own static website on{' '}
                <a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">
                  Netlify
                </a>
                ,{' '}
                <a href="https://vercel.com" target="_blank" rel="noopener noreferrer">
                  Vercel
                </a>
                ,{' '}
                <a href="https://surge.sh/" target="_blank" rel="noopener noreferrer">
                  Surge
                </a>
                , and many more!
              </li> */}
            </ul>
          </div>
        </div>
      </StyledAbout>
    </Layout>
  )
}

export const Head = () => <Seo title="About" />
